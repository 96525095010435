// Imports
// ------
import React, { useEffect, useState, useRef } from 'react';
import Logo from '@parts/Logo';
import Body from '@utils/bodylock';
import { headerState } from '@states/header';
import { action } from 'mobx';
import { motion } from 'framer-motion';
import { useLocation } from '@reach/router';

// Styles
// ------
import {
	Jacket,
	Home,
	PageList,
	PageLink,
	ExternalLink,
	ExternalLinkSuperScript,
	FakePageLink,
	Sublink,
	UpdatesButton,
	LoginButton,
} from './styles';

// Component
// ------
function Desktop({ nftEnabled, notify }) {
	const header = useRef();
	const [needsBg, setNeedsBg] = useState(false);
	const isHomePage = useLocation().pathname === '/';

	const handleUpdates = (e) => {
		e.preventDefault();
		Body.lock();

		const activateModal = action(() => {
			headerState.modalActive = true;
		});

		activateModal();
	};

	useEffect(() => {
		const setHeaderSize = action(() => {
			headerState.headerSize = header.current.offsetHeight;
		});

		setHeaderSize();
		window.addEventListener('resize', setHeaderSize);

		return () => {
			window.removeEventListener('resize', setHeaderSize);
		};
	}, []);

	useEffect(() => {
		window.onscroll = function (e) {
			if (window.scrollY >= 40) {
				setNeedsBg(true);
			} else {
				setNeedsBg(false);
			}
		};
	}, []);

	const pageTrans = {
		preventScrollJump: true,
		exit: {
			length: 1,
		},

		entry: {
			length: 1,
			trigger: ({ node, exit, e }) => {
				// node.style.position = 'absolute'
				node.style.top = '0px';
				// node.style.left = '0px'
			},
		},
	};

	return (
		<Jacket ref={header} needsBg={needsBg} isHomePage={isHomePage}>
			<motion.div
				initial={{ opacity: 0, y: `-50%` }}
				animate={{ opacity: 1, y: `0%` }}
				transition={{ duration: 1, delay: 0.25 }}>
				<Home to='/' {...pageTrans}>
					<Logo />
				</Home>

				<PageList>
					<li>
						<ExternalLinkSuperScript
							href={process.env.REACT_APP_PRODUCT_URL}
							{...pageTrans}>
							Marso
						</ExternalLinkSuperScript>
					</li>

					<li>
						<ExternalLink
							href={process.env.DOCS_URL}
							{...pageTrans}>
							Docs
						</ExternalLink>
					</li>

					{/* <li>
						<PageLink to='/pricing/' {...pageTrans}>
							Pricing
						</PageLink>
					</li> */}

					{nftEnabled && (
						<li>
							<PageLink to='/nft/' {...pageTrans}>
								NFT
							</PageLink>
						</li>
					)}

					{/* <li>
						<PageLink to='/material-library/' {...pageTrans}>
							Library
						</PageLink>
					</li> */}

					<li>
						<PageLink to='/blog/' {...pageTrans}>
							Blog
						</PageLink>
					</li>

					<li>
						<PageLink to='/careers/' {...pageTrans}>
							Careers
						</PageLink>
					</li>
				</PageList>

				<div style={{ display: 'flex', gap: '1.2rem' }}>
					<UpdatesButton onClick={handleUpdates}>Updates</UpdatesButton>
					<a href={process.env.REACT_APP_PRODUCT_URL}>
						<LoginButton>
							<img
								src='/icons/fingerprint-icon.svg'
								alt=''
								width='20'
								height='20'
								loading='lazy'
							/>
						</LoginButton>
					</a>
				</div>
			</motion.div>
		</Jacket>
	);
}

export default Desktop;
