// Imports
// ------
import styled, { css } from 'styled-components';
import { grad } from '@tackl';
import TransitionLink from 'gatsby-plugin-transition-link';

// Exports
// ------

export const Jacket = styled.div(
	(props) => css`
		position: fixed;
		top: ${props.top ? `calc(${props.top}px - 1px)` : `-1px`};
		left: 0;
		right: 0;
		padding: 0 2rem;
		margin: 0;
		z-index: 12;

		display: flex;
		justify-content: space-between;
		align-items: center;
		transition: all 0.2s linear;

		// NOTE • Makes notification bar look correct
		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			background: ${props.needsBg ? 'rgba(0,0,0,0.1)' : `transparent`};
			border-bottom: ${props.needsBg
				? '1px solid rgba(255,255,255,0.1)'
				: `1px solid rgba(255,255,255,0)`};
			backdrop-filter: ${props.needsBg ? `blur(2rem)` : `none`};
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;

			transform: translateY(${props.isOpen ? `-200%` : `0%`});
			transition: transform 1s ${props.theme.bezzy}, background 0.3s ease,
				backdrop-filter 0.3s ease, border-bottom 0.3s ease;
			will-change: transform;
		}

		${props.isOpen & props.hasNotification &&
		css`
			top: 1.2rem;
		`}
	`
);

export const Home = styled(TransitionLink)(
	(props) => css`
		display: inline-flex;
		padding: 1.2rem;

		svg {
			width: 2.4rem;
			height: 2.8rem;
		}
	`
);

export const MenuToggle = styled.button(
	(props) => css`
		padding: 1.2rem;
		background: transparent;

		span {
			position: relative;
			display: block;
			width: 2.4rem;
			height: 2.4rem;

			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;

				width: 100%;
				height: 2px;
				background: white;
				transition: all 1s ${props.theme.bezzy};
			}

			&:before {
				transform: translate(-50%, ${props.isOpen ? `-50%` : `-200%`})
					rotate(${props.isOpen ? `45deg` : `0deg`});
			}

			&:after {
				transform: translate(-50%, ${props.isOpen ? `-50%` : `200%`})
					rotate(${props.isOpen ? `135deg` : `0deg`});
			}
		}
	`
);

export const Menu = styled.nav(
	(props) => css`
		${grad}
		position: fixed;
		z-index: 11;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		opacity: ${props.isOpen ? 1 : 0};
		pointer-events: ${props.isOpen ? `auto` : `none`};
		transition: all 1s ${props.theme.bezzy};

		> ul {
			display: flex;
			flex-flow: column;
			align-items: flex-start;
			justify-content: flex-start;
			flex: 1;
			height: 100%;

			padding: 8.4rem 0 0 0;

			transform: translateY(${props.isOpen ? `0rem` : `6rem`});
			transition: all 1s ${props.theme.bezzy};

			> li {
				margin: 1.2rem 0;
				width: 100%;
			}
		}
	`
);

const sharedPageStyles = (props) => css`
	display: flex;
	font-family: ${props.theme.heading};
	font-weight: ${props.theme.semi};
	font-size: 1.6rem;
	line-height: 2.4rem;
	letter-spacing: 1px;
	text-transform: uppercase;

	padding: 1.2rem 2.4rem;
`;

export const Page = styled(TransitionLink)(
	(props) => css`
		${sharedPageStyles}
	`
);

export const External = styled(TransitionLink)(
	(props) => css`
		${sharedPageStyles}
	`
);

export const FakePage = styled.span(
	(props) => css`
		${sharedPageStyles}
		justify-content: space-between;
		align-items: center;

		${props.loading &&
		css`
			span {
				&:after {
					content: ' [In Progress]';
					text-transform: capitalize;
					color: props.theme.bc4;
				}
			}
		`}

		span,
		svg {
			transition: ${props.theme.ease};
		}

		span {
			opacity: ${props.isOpen ? 0.6 : 1};
		}

		svg {
			width: 1.6rem;
			height: 1.6rem;
			transform: rotate(${props.isOpen ? `-90deg` : `0deg`});
		}

		&.in-progress {
			color: rgba(255, 255, 255, 0.3);
			cursor: default;
			position: relative;
			max-width: max-content;

			&:after {
				content: 'In Progress';
				position: absolute;
				top: 1.15rem;
				left: calc(100% + 3.5rem);
				transform: translateX(-75%);
				color: #6c53ff;
				font-size: 1rem;
				font-weight: 300;
				text-transform: initial;
				width: max-content;
				transition: ${props.theme.ease};
			}
		}
	`
);

export const ListItem = styled.li(
	(props) => css`
		height: 4.8rem;

		${props.hasSubMenu &&
		css`
			height: ${props.isOpen ? `18rem` : `4.8rem`};
			overflow: ${props.hasSubMenu ? `hidden` : `auto`};
			transition: ${props.theme.ease};
		`}

		> ul {
			opacity: ${props.isOpen ? 1 : 0};
			pointer-events: ${props.isOpen ? `auto` : `none`};
			transform: translateY(${props.isOpen ? `0rem` : `1.2rem`});
			transition: ${props.theme.ease};

			> li {
				padding: 0.6rem 2.4rem;

				&:first-child {
					margin: 0.6rem 0 0 0;
				}
			}
		}
	`
);

export const Footer = styled.footer(
	(props) => css`
		position: absolute;
		bottom: 2.4rem;
		left: 0;
		right: 0;

		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
	`
);
