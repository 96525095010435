// Imports
// ------
import React, { useState } from 'react';
import ReactPlayer from 'react-player/file';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const Video = ({ url }) => {
	// NOTE • State
	const [ready, setReady] = useState(false);

	// NOTE • Functions
	const handleReady = () => {
		setReady(true);
	};

	return (
		<Jacket className='video'>
			<ReactPlayer
				url={url}
				onReady={handleReady}
				playing={ready}
				loop
				muted
				playsinline
				width={`100%`}
				height={`100%`}
				controls={false}
			/>
		</Jacket>
	);
};

export default Video;
